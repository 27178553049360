import React, { useState, useContext, useEffect } from 'react';
import Router, { useRouter } from 'next/router';
import { SCHEDULE_TYPE_FUTURE } from '../constants/scheduleType';

import toFixedFloat from '../helpers/toFixedFloat';
import { useUpsellProducts } from '../hooks/cart';
import { useCardList } from '../hooks/User';
import appContext from '../Context/appContext';
import useAPI from '../NetworkCall/API';
import BringItButton from './BringItButton';
import FriendsFamilyCollapse from './FriendsFamilyCollapse';
import { HYPUR, STRONGHOLD } from '../constants/paymentTypes';
import StorageService from '../services/storageService';
import EditAddress from './cart/EditAddress';
import { useDispatch, useSelector } from 'react-redux';
import { setCustomerInfo } from '../redux/slices/cartSlice';

export const handleDiscountedPrize = (totalBeforeDiscount, totalFeesIncluded) => {
  if (Number(totalFeesIncluded) > 0 && totalBeforeDiscount > parseFloat(totalFeesIncluded)) {
    return <span className="old-price strike-text mr-1">{`$ ${toFixedFloat(totalBeforeDiscount)}`}</span>;
  }
  return null;
};
const CurrentOrderInfo = ({ buttonText, detailsUpdated, setLoading, setCheckingOut, host }) => {
  const isLoggedIn = useSelector(state => state.user.isLoggedIn)
  const {
    customerData: { paymentType } = {},
    cartData: {
      totalBeforeDiscount,
      calculatedAmount,
      cart_items: cartItems = [],
      strongHoldEnabled,
      giftCardACHFees,
      giftCardHypurFees
    } = {}
  } = useSelector(state => state.cart) || {}
  const dispatchRedux = useDispatch()

  const { mutate: mutateCardList } = useCardList();

  const { createStrongholdToken } = useAPI();
  // const { trackEvent } = useSegment();
  const {
    data: { upsellZone },
    mutate: mutateUpsellProducts
  } = useUpsellProducts();

  // const noCards = !cardList || !cardList.length;

  useEffect(() => {
    // dispatch({ type: 'setCustomerInfo', payload: { emailError: null } });
    dispatchRedux(setCustomerInfo({ emailError: null }))
    mutateUpsellProducts();
    mutateCardList();
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      mutateCardList();
    }
  }, [isLoggedIn]);

  useEffect(() => {
    async function changePaymentType() {
      if (strongHoldEnabled) {
        await createStrongholdToken();
      }
    }

    changePaymentType();
  }, [strongHoldEnabled]);

  const areCartItemsPresent = cartItems?.length;

  if (!areCartItemsPresent && buttonText === 'Checkout') {
    return null;
  }

  const currency = cartItems && cartItems.length ? cartItems[0].category_currency : '$';

  const {
    query: { giftcard }
  } = Router;

  return (
    <>
      <div className="current-order-info ">
        <EditAddress className="d-lg-none" />
        <div className="container px-sm-0">
          <div className="d-flex ci-inner">
            <div className=" left-sec " />

            <div className=" right-sec ">
              <div className="text-left">
                <div className="d-flex">
                  <>
                    {!giftcard ? handleDiscountedPrize(totalBeforeDiscount, calculatedAmount) : null}
                    <strong className="new-price">
                      {currency}
                      {toFixedFloat(calculatedAmount)}
                    </strong>
                  </>
                </div>
              </div>

              <div className={`${isLoggedIn ? 'col-5' : 'col-6'} d-flex pr-0`}>
                <BringItButton
                  detailsUpdated={detailsUpdated}
                  buttonText={!isLoggedIn ? 'Log in to Buy Now' : buttonText}
                  setLoading={setLoading}
                  setCheckingOut={setCheckingOut}
                  host={host}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

CurrentOrderInfo.defaultProps = {
  buttonText: 'Bring It',
  title: 'Sub Total'
};

export default CurrentOrderInfo;
